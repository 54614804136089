<template>
  <b-container>
    <b-row class="mt-2">

      <!-- Left Text-->
      <b-col
        cols="8"
        class="d-flex flex-column align-items-center p-0 m-0"
      >
        <h1 class="mt-lg-5">Search for events.<br/>Connect, Learn, Engage.</h1>
        <validation-observer
            ref="searchForm"
            #default="{invalid}"
            class="w-50"
          >
            <b-form
              class="mt-2"
              @submit.prevent="search"
            >
              <validation-provider
                #default="{ errors }"
                name="Search"
                vid="search"
                rules="required"
              >
                <b-input-group class="w-100 rounded-lg">
                    <b-form-input
                      id="search"
                      v-model="username"
                      :state="errors.length > 0 ? false:null"
                      name="search"
                      size="lg"
                      placeholder="Search Events"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  <b-input-group-append>
                    <b-button
                      type="submit"
                      variant="primary"
                      :disabled="invalid"
                    >
                      <font-awesome-icon :icon="['fal', 'search']"/>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </validation-provider>
            </b-form>
        </validation-observer>
      </b-col>

      <!-- Login-->
      <b-col
        cols="4"
        class="d-none d-lg-flex justify-content-center align-items-center auth-bg px-2 p-0 m-0"
      >
        

      </b-col>
    <!-- /Login-->
    </b-row>
  </b-container>
</template>

<script>
/* eslint-disable global-require */
import posthog from 'posthog-js'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import HummzLogo from '@/layouts/components/HummzLogo.vue'
import {
  BContainer, BRow, BCol, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BForm, BButton, VBTooltip,
} from 'bootstrap-vue'


export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    HummzLogo,
  },
  data() {
    return {
      search: '',
      events: [],
      required,
    }
  },
  computed: {
  },
  watch: {
  },
  methods: {
  },
  beforeMount() {
  },
}
</script>

<style lang="scss">

</style>
