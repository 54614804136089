<template>
  <section>
    <login v-if="eventMode==='login'" />
    <register-full-screen :mode="eventMode" :orderId="orderId" v-if="eventMode==='register' && pages && pages.login && pages.login.loginView && pages.login.loginView==='fullscreen'" />
    <register :mode="eventMode" :orderId="orderId" v-else-if="eventMode === 'register'" />
    <!-- <register :mode="eventMode" v-else-if="eventMode !== 'search'" /> -->
    <!-- <explore v-else /> -->
  </section>
</template>

<script>
/* eslint-disable global-require */
import store from "@/store/index";
import Register from '@/views/pages/authentication/Register.vue'
import RegisterFullScreen from '@/views/pages/authentication/RegisterFullScreen.vue'
import Login from '@/views/pages/authentication/Login.vue'
import Explore from '@/views/pages/authentication/Explore.vue'

export default {
  components: {
    Register,
    Login,
    RegisterFullScreen,
    Explore,
  },
  metaInfo() {
    return {
      title: 'Home'
    }
  },
  data() {
    return {
      orderId: null,
      eventMode: '',
      pages: store.state.event.pages,
    }
  },
  computed: {
    event() {
      return store.state.event.event;
    },
  },
  watch: {
    event() {
      // console.log('watch event params', this.$route.params);
      if (this.$route.query && this.$route.query.p) {
        // preview param set. compare preview key and redirect to login
        // this.$router.push({ name: 'auth-login' })
        if (store.state.event?.event?.meta?.preview && store.state.event?.event?.meta?.preview === this.$route.query.p) {
          this.eventMode = 'login'
        }
      } else if (store.state.event.event && store.state.event.event.scheduledStart) {
        let startDate = this.$date(store.state.event.event.scheduledJoin || store.state.event.event.scheduledStart)
        // console.log('home-event', startDate)
        if (this.$date().isBefore(startDate)) {
          // this.$router.replace({ name: 'auth-register' })
          this.eventMode = 'register'
        } else {
          // this.$router.replace({ name: 'auth-login' })
          this.eventMode = 'login'
        }
      } else {
        this.eventMode = 'search'
      }
      // console.log('home-event', this.eventMode )
      this.$meta().refresh()
      return store.getters["event/getEvent"];
    },
  },
  methods: {
    checkEventSlug() {
      // console.log('checkEventSlug params', this.$route);
      let eventSlug = '';
      if(this.$route.params && this.$route.params.slug) {
        eventSlug = this.$route.params.slug
        eventSlug = eventSlug.replace(/\/e\//g, '').replace(/\//g, '')
        store.commit('event/SET_EVENT_SLUG', eventSlug)
        localStorage.setItem('hmzevent', eventSlug)
      }
      store.dispatch("event/getEvent", eventSlug);
    }
  },
  beforeMount() {
    // console.log('beforeMount params', this.$route);
    this.checkEventSlug()
  },
  mounted() {
    // if an existing order is passed in the query params, set it in the store
    if (this.$route.query.o) {
        const orderId = this.$route.query.o
        if (orderId) {
          this.orderId = orderId
        }
    }
    if (window.fbq && store?.state?.event?.event?.meta?.fbPixels && Array.isArray(store.state.event.event.meta.fbPixels) && store.state.event.event.meta.fbPixels.length > 0) {
        // console.log(`setting fb pixel: ${store.state.event.event.title}`, fbq.getState())
        const fbqState = fbq.getState()
        if (!fbqState || !fbqState.pixels || !Array.isArray(fbq.pixels) || fbq.pixels.length === 0) {
          for (const pixel of store.state.event.event.meta.fbPixels) {
            fbq('init', pixel)
          }
          fbq('track', 'PageView');
        }
      // } else {
      //   console.log('fb pixel code not found', store.state.event.event, fbq.getState())
      }
  },
  setup() {
    // console.log('setup params', this.$route.params);
    // this.checkEventSlug()
  },
};
</script>