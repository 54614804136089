<template>
  <div class="">
    <b-row class="m-0">
      <!-- Left Text-->
      <b-col lg="7" class="d-none d-lg-flex align-items-start p-0 m-0 bg-white">
        <swiper v-if="pages && pages.login && pages.login.banners && pages.login.banners.length>1" effect="fade"
          :options="swiperOptions" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'">
          <swiper-slide v-for="(banner, index) in pages.login.banners" :key="index">
            <b-img-lazy :src="banner.image" fluid style="height:100vh !important;" />
          </swiper-slide>
        </swiper>
        <div v-if="pages && pages.login && pages.login.banners && pages.login.banners.length===1">
          <b-img-lazy :src="pages.login.banners[0].image" fluid style="height:100vh !important;" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <b-col lg="5" class="d-flex flex-column justify-content-between align-items-center auth-bg px-2 p-0 m-0 bg-white">
        <div class="mt-1 w-100 d-flex flex-column justify-content-center align-items-center">
          <b-img-lazy v-if="event.logo && (!pages || (pages && pages.login && pages.login.eventLogo==='top'))"
            :src="event.logo" style="max-height: 200px" />
          <!-- Event title, countdown and register button -->
          <b-card class="mx-auto w-100 p-1 rounded-lg border-primary" no-body>
            <b-card-body class="p-0 m-0 d-flex flex-column align-items-stretch">
              <div class="d-flex flex-row justify-content-between">
                <div>
                  <h1>{{ event.title }}</h1>
                  <h5>{{ event.subtitle }}</h5>
                </div>
                <b-img-lazy id='event-logo'
                  v-if="event.logo && (!pages || (pages && pages.login && pages.login.eventLogo==='right'))"
                  :src="event.logo" style="max-width: 80px; max-height: 80px " />
                <b-popover target='event-logo' triggers="hover" custom-class="rounded-lg" placement="auto"
                  v-if="event.logo && (!pages || (pages && pages.login && pages.login.eventLogo==='right'))">
                  <b-img :src="event.logo" style="max-width: 250px;height: auto;object-fit: contain;" />
                </b-popover>
              </div>
              <b-row>
                <b-col cols="5" class="border-right-primary">
                  <span class="font-weight-bolder text-dark small">START TIME</span><br />
                  {{this.$date(event.scheduledStart).tz(this.$date.tz.guess(), {locale: locale}).format('MMM DD, YYYY HH:mm')}}
                </b-col>
                <b-col cols="5" class="border-right-primary">
                  <span class="font-weight-bolder text-dark small">END TIME</span><br />
                  {{this.$date(event.scheduledEnd).tz(this.$date.tz.guess(), {locale: locale}).format('MMM DD, YYYY HH:mm')}}
                </b-col>
                <b-col cols="2" style="border-bottom:solid 1px primary">
                  <add-to-calendar :event="event.slug" />
                </b-col>
                <b-col cols="12" class="m-0 p-0 mb-1" v-if="eventMode==='register'">
                  <vue-countdown-timer @ended="redirectToJoin" :start-time="this.$date().valueOf()"
                    :end-time="this.$date(event.scheduledJoin).tz(this.$date.tz.guess(), {locale: locale}).valueOf()"
                    :interval="1000" label-position="begin" :end-text="'Join Now'" :day-txt="'days'" :hour-txt="'hours'"
                    :minutes-txt="'minutes'" :seconds-txt="'seconds'" class="m-1 bg-light-primary rounded-lg shadow">
                    <template slot="countdown" slot-scope="scope">
                      <b-row>
                        <b-col v-if="scope.props.showDay" class="text-center">
                          <span class="text-primary" style="font-size: 2rem; font-weight: bold">{{ scope.props.days
                          }}</span> <br />{{ scope.props.dayTxt }}
                        </b-col>
                        <b-col v-if="scope.props.showHour" class="text-center">
                          <span class="text-primary" style="font-size: 2rem; font-weight: bold">{{ scope.props.hours
                          }}</span> <br />{{ scope.props.hourTxt }}
                        </b-col>
                        <b-col v-if="scope.props.showMinute" class="text-center">
                          <span class="text-primary" style="font-size: 2rem; font-weight: bold">{{ scope.props.minutes
                          }}</span> <br />{{ scope.props.minutesTxt }}
                        </b-col>
                        <b-col class="text-center"><span class="text-primary"
                            style="font-size: 2rem; font-weight: bold">{{ scope.props.seconds }}</span> <br />{{
                            scope.props.secondsTxt }}</b-col>
                      </b-row>
                    </template>
                  </vue-countdown-timer>

                </b-col>
              </b-row>
              <div v-if="eventMode==='register'" class="flex-grow-1 d-flex flex-column justify-content-end">
                <b-button variant="primary" block @click="showRegisterModal">Register</b-button>
              </div>
              <div v-if="eventMode==='login'" class="flex-grow-1 d-flex flex-column justify-content-end">
                <validation-observer ref="loginForm" #default="{invalid}">
                  <b-form class="auth-login-form mt-2" @submit.prevent="login">
                    <b-form-group label="Email" label-for="login-email">
                      <validation-provider #default="{ errors }" name="Email" vid="email" rules="required|email">
                        <b-form-input id="login-email" v-model="username" :state="errors.length > 0 ? false:null"
                          name="login-email" placeholder="your.name@example.com" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-button type="submit" variant="primary" block :disabled="invalid">
                      Register
                    </b-button>
                  </b-form>
                </validation-observer>
              </div>
            </b-card-body>
          </b-card>
          <!-- /Event title, countdown and register button -->

          <!-- Event organizers/hosts -->
          <!-- Multiple organizers/hosts-->
          <b-card v-if="event && event.organizer && Array.isArray(event.organizer)"
            class="mx-auto w-100 p-1 d-flex flex-column justify-content-between rounded-lg border-primary" no-body>
            <b-card-body class="p-0 m-0">
              <h6 class="font-weight-bolder">HOSTED BY</h6>
              <div v-for="(organizer, index) in event.organizer" :key="index" class="mb-2">
                <b-img-lazy :id="'organizerlogo-'+index" v-if="organizer.logo" :src="organizer.logo"
                  :alt="organizer.name" width="80px" right />
                <b-popover :target="'organizerlogo-'+index" triggers="hover" custom-class="rounded-lg box-shadow-4"
                  placement="auto"
                  v-if="event.logo && (!pages || (pages && pages.login && pages.login.eventLogo==='right'))">
                  <b-img :src="organizer.logo" style="max-width: 250px; height: auto;object-fit: contain;" />
                </b-popover>
                <h1>{{ organizer.name }}</h1>
                <h5 v-if="organizer.contactDesignation">{{ organizer.contactDesignation }}</h5>
                <h6 v-if="organizer.contactAddress">{{organizer.contactAddress}}</h6>
                <h6 v-if="organizer.contactPhone">{{organizer.contactPhone}}</h6>

                <div v-if="organizer.social">
                  <b-link v-if="organizer.website" :href="organizer.website" target="_blank">
                    <font-awesome-icon :icon="['fal', 'globe']" size="lg" class="mr-1" />
                  </b-link>
                  <b-link v-if="organizer.contactEmail" :href="'mailto:' + organizer.contactEmail" target="_blank">
                    <font-awesome-icon :icon="['fal', 'at']" size="lg" class="mr-1" />
                  </b-link>
                  <b-link v-for="socialLink in Object.keys(organizer.social)" :key="socialLink"
                    :href="organizer.social[socialLink]" target="_blank">
                    <font-awesome-icon :icon="['fab', socialLink]" size="lg" class="mr-1" />
                  </b-link>
                </div>
                <div v-else>
                  <b-link v-if="organizer.website" :href="organizer.website" target="_blank">
                    <font-awesome-icon :icon="['fal', 'globe']" size="lg" class="mr-1" />
                  </b-link>
                  <b-link v-if="organizer.contactEmail" :href="'mailto:' + organizer.contactEmail" target="_blank">
                    <font-awesome-icon :icon="['fal', 'at']" size="lg" class="mr-1" />
                  </b-link>
                </div>
              </div>
            </b-card-body>
          </b-card>
          <!-- /Multiple organizers/hosts-->
          <!-- Single organizer/host-->
          <b-card v-if="event && event.organizer && !Array.isArray(event.organizer)"
            class="mx-auto w-100 p-1 d-flex flex-column justify-content-between rounded-lg border-primary" no-body>
            <b-card-body class="p-0 m-0">

              <h6 class="font-weight-bolder">HOSTED BY</h6>
              <b-img-lazy id="organizerlogo-1" v-if="event.organizer.logo" :src="event.organizer.logo"
                :alt="event.organizer.name" width="80px" right />
              <b-popover target="organizerlogo-1" triggers="hover" custom-class="rounded-lg box-shadow-4"
                placement="auto"
                v-if="event.logo && (!pages || (pages && pages.login && pages.login.eventLogo==='right'))">
                <b-img :src="organizer.logo" style="max-width: 250px; height: auto; object-fit: contain;" />
              </b-popover>
              <h1>{{ event.organizer.name }}</h1>
              <h5 v-if="event.organizer.contactDesignation">{{ event.organizer.contactDesignation }}</h5>
              <h6 v-if="event.organizer.contactAddress">{{event.organizer.contactAddress}}</h6>
              <h6 v-if="event.organizer.contactPhone">{{event.organizer.contactPhone}}</h6>

              <div v-if="event.organizer.social">
                <b-link v-if="event.organizer.website" :href="event.organizer.website" target="_blank">
                  <font-awesome-icon :icon="['fal', 'globe']" size="lg" class="mr-1" />
                </b-link>
                <b-link v-if="event.organizer.contactEmail" :href="'mailto:' + event.organizer.contactEmail"
                  target="_blank">
                  <font-awesome-icon :icon="['fal', 'at']" size="lg" class="mr-1" />
                </b-link>
                <b-link v-for="socialLink in Object.keys(event.organizer.social)" :key="socialLink"
                  :href="event.organizer.social[socialLink]" target="_blank">
                  <font-awesome-icon :icon="['fab', socialLink]" size="lg" class="mr-1" />
                </b-link>
              </div>
              <div v-else>
                <b-link v-if="event.organizer.website" :href="event.organizer.website" target="_blank">
                  <font-awesome-icon :icon="['fal', 'globe']" size="lg" class="mr-1" />
                </b-link>
                <b-link v-if="event.organizer.contactEmail" :href="'mailto:' + event.organizer.contactEmail"
                  target="_blank">
                  <font-awesome-icon :icon="['fal', 'at']" size="lg" class="mr-1" />
                </b-link>
              </div>
            </b-card-body>
          </b-card>
          <!-- /Single organizer/host-->
          <!-- /Event organizers/hosts -->
        </div>
        <div class="w-100 text-center">
          Powered by
          <b-img-lazy src="https://cdn.humz.it/hummz/hummz-name-color.svg" alt="Hummz" height="25px" width="auto" />
        </div>
      </b-col>
    </b-row>

    <!-- Register modal -->
    <b-modal id="modal-register" centered cancel-disabled :title="event.title" size="lg" footer-class="d-none">
      <h2>Register</h2>
      <p class="text-muted">Fields marked with an * are required.</p>
      <validation-observer ref="registerForm" #default="{ invalid }">
        <b-form class="auth-login-form mt-2" @submit.prevent="register">
          <b-row>
            <b-col cols="12" lg="3">
              <b-form-group label="Title *" label-for="register-title">
                <validation-provider #default="{ errors }" name="Title" vid="title" rules="required">
                  <b-form-select id="register-title" v-model="booking.title" label="title" :options="[
                    { value: null, text: '' },
                    { value: 'Mx', text: 'Mx' },
                    { value: 'Ms', text: 'Ms' },
                    { value: 'Mr', text: 'Mr' },
                    { value: 'Dr', text: 'Dr' },
                    { value: 'Prof', text: 'Prof' },
                  ]" name="register-title" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="3">
              <b-form-group label="First Name *" label-for="register-firstname">
                <validation-provider #default="{ errors }" name="First Name" vid="firstname" rules="required">
                  <b-form-input id="register-firstname" v-model="booking.firstname"
                    :state="errors.length > 0 ? false : null" name="register-firstname" placeholder="" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="3">
              <b-form-group label="Middle Name" label-for="register-middlename">
                <validation-provider #default="{ errors }" name="Middle Name" vid="middlename" rules="">
                  <b-form-input id="register-middlename" v-model="booking.middlename"
                    :state="errors.length > 0 ? false : null" name="register-middlename" placeholder="" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="3">
              <b-form-group label="Last Name *" label-for="register-lastname">
                <validation-provider #default="{ errors }" name="Last Name" vid="lastname" rules="required">
                  <b-form-input id="register-lastname" v-model="booking.lastname"
                    :state="errors.length > 0 ? false : null" name="register-lastname" placeholder="" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" lg="6">
              <!-- email -->
              <b-form-group label="Email *" label-for="register-email">
                <validation-provider #default="{ errors }" name="Email" vid="email" rules="required|email">
                  <b-form-input id="register-email" v-model="booking.email" :state="errors.length > 0 ? false : null"
                    name="register-email" placeholder="your.name@example.com" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="6">
              <b-form-group label="Mobile" label-for="register-mobile">
                <validation-provider #default="{ errors }" name="Mobile" vid="tel" rules="">
                  <b-form-input id="register-mobile" v-model="booking.mobile" :state="errors.length > 0 ? false : null"
                    name="register-mobile" placeholder="" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" lg="6">
              <b-form-group label="Organization" label-for="register-organization">
                <validation-provider #default="{ errors }" name="Organization" vid="organization" rules="">
                  <b-form-input id="register-organization" v-model="booking.organization"
                    :state="errors.length > 0 ? false : null" name="register-organization" placeholder="" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="6">
              <b-form-group label="Job Title" label-for="register-designation">
                <validation-provider #default="{ errors }" name="Job Title" vid="" rules="">
                  <b-form-input id="register-designation" v-model="booking.designation"
                    :state="errors.length > 0 ? false : null" name="register-designation" placeholder="" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-button type="submit" variant="primary" :disabled="invalid" class="float-right"
            :id="eventMode===login ? 'btnCheckIn' : 'btnRegistrationComplete'">
            <span v-if="eventMode==='login'">Check In</span><span v-else>Register</span>
          </b-button>
          <b-button type="button" variant="outline-secondary" class="mr-1 float-right" @click="hideRegisterModal"
            id="btnCancel">
            Cancel
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal id="modal-thankyou" centered :title="event.title" size="lg" ok-title="Close" ok-variant="primary" ok-only>
      <h4>Thank you registering for the {{event.title}}.</h4>
      <br />
      <h5 v-if="event.meta && event.meta.website">For more information about the {{event.title}}, please visit <a
          :href="event.meta.website" target="_blank">{{event.meta.website}}</a></h5>
    </b-modal>
  </div>
</template>

<script>
import posthog from 'posthog-js'
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import HummzLogo from "@/layouts/components/HummzLogo.vue";
import AppFooter from "@core/layouts/components/AppFooter.vue";
import {
  BContainer,
  BFormRow,
  BRow,
  BCol,
  BLink,
  BCarousel,
  BCarouselSlide,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BFormSelect,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BImg,
  BImgLazy,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
  BTab,
  BTabs,
  BModal,
  BPopover,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import 'swiper/css/swiper.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VueCountdownTimer from '@/layouts/components/VueCountdownTimer.vue';
import AddToCalendar from '@/layouts/components/AddToCalendar.vue';
import Partners from '@/views/pages/partners/Partner.vue';
import AgendaCard from "@/views/pages/sessions/AgendaCard.vue";
import Speakers from '@/views/pages/participants/ParticipantCard.vue'
import store from "@/store/index";
import api from '@/store/api/api';

export default {
  metaInfo() {
    return {
      title: this.eventMode === 'login' ? 'Check In' : 'Register'
    }
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-modal": BModal,
  },
  components: {
    AppFooter,
    Swiper,
    SwiperSlide,
    BContainer,
    BFormRow,
    BRow,
    BCol,
    BLink,
    BCarousel,
    BCarouselSlide,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BFormSelect,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BImg,
    BImgLazy,
    BForm,
    BButton,
    BAlert,
    BTabs,
    BTab,
    BModal,
    BPopover,
    ValidationProvider,
    ValidationObserver,
    VueCountdownTimer,
    AddToCalendar,
    Partners,
    AgendaCard,
    Speakers,
    HummzLogo,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      locale: window.navigator.language || 'en-IN',
      showModal: true,
      swiperOptions: {
        loop: true,
        effect: "fade",
        // autoHeight: true,
        // spaceBetween: 0,
        // grabCursor: true,
        // centeredSlides: true,
        // slidesPerView: '1',
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        // pagination: {
        //   el: '.swiper-pagination',
        // },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      status: "",
      password: '',
      username: '',
      booking: {
        title: '',
        firstname: '',
        middlename: '',
        lastname: '',
        email: '',
        mobile: '',
        organization: '',
        designation: ''
      },
      requiresRegistration: true,
      // validation rules
      required,
      email,
      // event: store.state.event.event,
      pages: store.state.event.pages,
    };
  },
  computed: {
    eventMode() {
      let _eventMode = this.mode
      if (this.mode === 'register' && this.$date().isAfter(this.event.scheduledJoin)) {
        _eventMode = 'login'
      }
      return _eventMode
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    event() {
      return store.state.event.event;
    },
    sessions() {
      return store.state.sessions.sessions;
    },
    partners() {
      return store.state.partners.partners;
    },
    speakers() {
      return store.getters['attendees/byType']('speaker', '');
    },
    exhibitors() {
      return [];
    },
  },
  watch: {
    event() {
      return store.getters["event/getEvent"];
    },
  },
  methods: {
    showRegisterModal() {
      this.$bvModal.show('modal-register')
    },
    hideRegisterModal() {
      this.$bvModal.hide('modal-register')
    },
    async register() {
      // console.log('register', this.booking)
      let location = {}
      try {
        // const response = await api.get('https://geolocation-db.com/json/');
        // location = response.data;
        // // console.log('location', location);
        // if (location.country_code) {
        //   this.booking.country = location.country_code
        // }
        const response = await api.get('https://api.hummz.net/v2/countries/ip2location');
        location = response.data;
        if (location.countryCode) {
          this.booking.country = location.countryCode
        }
      } catch (error) {
        // report error
        console.error('Location error', error);
      }
      let bookingData = {
        data: this.booking,
        ticket: {
          type: "delegate",
          name: "Delegate",
        },
        meta: {
          urlParams: "",
          location: location,
          metadata: {
            timezone: this.$date.tz.guess(),
            offset: new Date().getTimezoneOffset(),
            referrer: document.referrer,
            userAgent: navigator.userAgent,
            pathName: window.location.href || "https://www.hummz.events",
            onLine: navigator.onLine,
          },
        },
      };
      // console.log('bookingData', bookingData)
      // create a new registration
      try {
        const bookingResponse = await api.post(`/events/register/${this.event.slug}`, bookingData)
        if (bookingResponse && !bookingResponse.data.error) {
          fbq('track', 'CompleteRegistration');
          this.registationStatus = 'success'
          this.registrationResponse = bookingResponse.data
          this.hideRegisterModal()
          if (this.eventMode === 'login') {
            this.username = this.booking.email
            this.login()
          } else {
            this.$bvModal.show('modal-thankyou')
          }
        } else {
          if (bookingResponse.data.error) {
            this.registationStatus = 'error'
            this.registrationResponse = bookingResponse.data.error
          }
        }
      } catch (error) {
        this.registationStatus = 'error'
        this.registrationResponse = error.message
      }
    },
    login() {
      // let location = {}
      // try {
      //   const response = await api.get('https://geolocation-db.com/json/');
      //   location = response.data;
      //   // console.log('location', location);
      //   if (location.country_code) {
      //     this.booking.country = location.country_code
      //   }
      // } catch (error) {
      //   // report error
      //   console.error('Location error', error);
      // }
      // console.log('login');
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          // console.log('login success');
          store.dispatch('account/login', { email: this.username, password: this.password, eventId: this.event.id })
            .then((response) => {
              posthog.identify(this.$store.state.account.token, { name: this.$store.state.account.user.name, email: this.$store.state.account.user.email });
              this.$ability.update([{ action: 'manage', subject: 'all', },])
              // login to event channel
              store.dispatch('joinEventChannel')
              // Redirect to default event navigation
              let defaultNavigation = '/reception'
              if (this.event.meta && this.event.meta.defaultNavigation && this.event.meta.defaultNavigation.length > 0) {
                defaultNavigation = this.event.meta.defaultNavigation
              } else if (this.event.type === 'webinar') {
                defaultNavigation = '/stage'
              }
              this.$router.replace(defaultNavigation).then(() =>
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-left',
                  props: {
                    title: `Welcome ${this.$store.state.account.user.name || this.$store.state.account.user.username}`,
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: `You have successfully checked in. We invite you to start exploring!`,
                  },
                })
              )
            })
            .catch((error) => {
              console.error(error);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Registration Required`,
                  icon: 'StopIcon',
                  variant: 'error',
                  text: 'It seems you are not registered. Did you enter your registered email?',
                  position: 'bottom-left',
                },
              })
              this.booking.email = this.username
              this.showRegisterModal()
            })
        }
      })
    },
    redirectToJoin() {
      this.$router.replace('/login')
    },
  },
  beforeMount() {
    store.dispatch("event/getEvent");
    store.dispatch('attendees/hideSidebar')
  },
  mounted() {
    if (window.fbq && store?.state?.event?.event?.meta?.fbPixels && Array.isArray(store.state.event.event.meta.fbPixels) && store.state.event.event.meta.fbPixels.length > 0) {
      // console.log(`setting fb pixel: ${store.state.event.event.title}`, fbq.getState())
      const fbqState = fbq.getState()
      if (!fbqState || !fbqState.pixels || !Array.isArray(fbq.pixels) || fbq.pixels.length === 0) {
        for (const pixel of store.state.event.event.meta.fbPixels) {
          fbq('init', pixel)
        }
        fbq('track', 'PageView');
      }
      // } else {
      //   console.log('fb pixel code not found', store.state.event.event, fbq.getState())
    }
  },
  props: {
    mode: {
      type: String,
      required: false,
      default: () => 'register'
    },
    orderId: {
      type: String,
      required: false,
      default: () => ''
    },
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
@import '@core/scss/vue/libs/swiper.scss';
</style>
